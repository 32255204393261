import React from 'react';
import "./corporateSeriesControl.css";
import { Container, Row, Col, Card } from "react-bootstrap";
import CorporateSeriesSlider from './CorporateSeriesSlider';

export default function CorporateSeriesControl() {
  return (
    <div className='corporateSeriesContainer' id="corporateSeries">
        <div className='container-fluid'>
            <Row>
                <Col md="12">
                    <div className='corporateSeriesheader'>Corporate Series</div>
                    <div className='corporateSeriesheadersubtxt'>Partners</div>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <CorporateSeriesSlider />
                </Col>
            </Row>
        </div>
    </div>
  )
}
