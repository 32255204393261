import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Rightimage from "../../assets/testimonalrightImg.svg";
import TestimonalCard from './TestimonalCard';

import "./testimonal.css";

export default function TestimonalControl() {
    return (
        <div className='container' id="testimonials"> 
      {/* <Container > */}
            <Row>
                <Col md={6}>
                    <div className='testimonalText'>Testimonials</div>
                </Col>
            </Row>
            <Row>
                <Col md={6} sm={12}>
                    <Col md={12} sm={12}>
                        <TestimonalCard
                            id={1}
                            name={`Dr. Michael Clark, Chairman, Founder and CEO of Fusionetics and Founder of the National Academy of Sports Medicince and former team physical therapist to the NBA's Phoenix Suns.
                        `}
                            content={`“Phil Weber was ahead of his time. He helped NBA players increase their effectiveness by focusing not just on skills alone, but on their mindsets as well. He started using this approach before mindset really became a thing that so many sports teams and businesses now embrace as a competitive edge.`}
                        />
                    </Col>
                    <Row>
                        <Col md={6} sm={12}>
                            <TestimonalCard
                                id={2}
                                name={`Isaac Lidsky, New York Times best-selling author of Eyes Wide Open`}
                                content={`"You’ll benefit greatly from the wealth of knowledge Phil shares on mastering your reality … and then so will many others.” `}
                            />
                        </Col>
                        <Col md={6} sm={12}>
                            <TestimonalCard
                                id={3}
                                name={`Mike D’Antoni Former NBA Coach, New Orleans Pelicans`}
                                content={`“I worked with Phil for ten straight years when we were on the coaching staff together with the Suns and then the Knicks. A big part of his work in developing players was to have them focus on the mental aspect of things, and he was a role model for that in a very genuine way. Nothing could get him down.”`}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col md={6} sm={12}>
                    {/* <img src={Rightimage} className="testimonalImage" /> */}
                    <div className='testimonalImageRigt'></div>
                </Col>
            </Row>
        {/* </Container> */}
        </div>
    )
}
