import React from 'react';
import Topbar from "../components/topbar/Topbar";
import About from '../components/about/About';
import Speech from '../components/speech/Speech';
import MyBook from '../components/mybook/MyBook';
import LatestNews from '../components/latestNews/LatestNews';
import News from '../components/news/News';
import PodcacastControl from "../components/podcast/PodcacastControl";
import TestimonalControl from '../components/testimonal/TestimonalControl';
import Footer from "../components/footer/Footer";
import CorporateSeriesControl from "../components/corporateSeries/CorporateSeriesControl";

import '../App.css';
import "../components/topbar/topbar.css";

function Home() {
    return (
        <div>
            <Topbar />
            <About />
              <Speech />
            <MyBook />
            <LatestNews />
            <News />
            <PodcacastControl />
            <CorporateSeriesControl />
            <TestimonalControl />
            <Footer />  
        </div>
    )
}

export default Home