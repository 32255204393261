import React from 'react';
import { Row, Col } from "react-bootstrap";

function InterpretationDefinitions() {
    return (
        <div>
            <div className='heading1'>
                Interpretation and Definitions
            </div>
            <hr />
            <div className='heading1Content'>
                <Row>
                    <Col md={3}>

                    </Col>
                    <Col md={9}>
                        <Row>
                            <Col>
                                <div className='heading1ContentSubheading1'>
                                    Interpretation
                                </div>
                                <div className='heading1ContentinnerCont'>
                                    The words of which the initial letter is capitalized have meanings defined under the
                                    following conditions. The following definitions shall have the same meaning regardless of
                                    whether they appear in singular or in plural.
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <div className='heading1ContentinnerCont'>
                                    <div className='heading1ContentSubheading1'>
                                        Definitions
                                    </div>
                                    <div className='heading1ContentinnerCont'>
                                        For the purposes of this Privacy Policy:
                                    </div>
                                    <div className='heading1ContentinnerCont'>
                                        <span className='boldtext'>Account</span> means a unique account created for You to access our Service or parts of our Service.
                                    </div>
                                    <div className='heading1ContentinnerCont'>
                                        <span className='boldtext'>Company</span>
                                        <span> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to coachphilweber.com.</span>
                                    </div>
                                    <div className='heading1ContentinnerCont'>
                                        <span className='boldtext'>Cookies</span>
                                        <span>  are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.</span>
                                    </div>
                                    <div className='heading1ContentinnerCont'>
                                        <span className='boldtext'>Cookies</span>
                                        <span>  are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.</span>
                                    </div>

                                    <div className='heading1ContentinnerCont'>
                                        <span className='boldtext'>Country</span>
                                        <span> refers to: New York, United States
                                            Device means any device that can access the Service such as a computer, a cellphone or a digital tablet.</span>
                                    </div>
                                    <div className='heading1ContentinnerCont'>
                                        Personal Data is any information that relates to an identified or identifiable individual.
                                    </div>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className='heading1ContentinnerCont' style={{ marginTop: "13px" }}>
                                    <div className='heading1ContentinnerCont'>
                                        <span className='boldtext'>Service</span>
                                        <span>refers to the Website.</span>
                                    </div>

                                    <div className='heading1ContentinnerCont'>
                                        <span className='boldtext'>Service Provider </span>
                                        <span>
                                            means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.
                                        </span>
                                    </div>

                                    <div className='heading1ContentinnerCont'>
                                        <span className='boldtext'>Usage Data </span>
                                        <span>
                                            refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).
                                        </span>
                                    </div>

                                    <div className='heading1ContentinnerCont'>
                                        <span className='boldtext'>Website </span>
                                        <span>
                                            refers to coachphilweber.com, accessible from www.coachphilweber.com
                                        </span>
                                    </div>

                                    <div className='heading1ContentinnerCont'>
                                        <span className='boldtext'>You </span>
                                        <span>
                                            means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.
                                        </span>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default InterpretationDefinitions