import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import NavigationBar from '../components/topbar/NavigationBar';
import Footer from '../components/footer/Footer';
import InterpretationDefinitions from "../components/privacies/InterpretationDefinitions";
import Header from '../components/topbar/Header';
import "../styles/Privacies/privacies.css";
import HeadingWithboldTextCpontent from '../components/privacies/HeadingWithboldTextCpontent';
import BoldTextContetentInLine from '../components/privacies/BoldText';


function TermsAndConditions() {
  return (
    <div className='privaciPoliciesContainer'>
      <div id='headerContainer'>
        <Header />
      </div>
      <div className='bottomNav'>
        <div className='container-fluid'>
          Terms and Conditions
        </div>
      </div>
      <div className='privaciContentContainer container-fluid'>
        <div className='heading1'>
          Interpretation and Definitions
        </div>
        <hr />
        <Row>
          <Col md={3}>
          </Col>
          <Col md={9}>
            <HeadingWithboldTextCpontent
              Heading={`Interpretation`}
              Content={`The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.`}
            />
            <HeadingWithboldTextCpontent
              Heading={`Definitions`}
              Content={`For the purposes of these Terms and Conditions:`}
            />

            <BoldTextContetentInLine
              Heading={`Affiliate`}
              Contetnt={` means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.`}
            />

            <BoldTextContetentInLine
              Heading={`Country `}
              Contetnt={` refers to: New York, United States`}
            />

            <BoldTextContetentInLine
              Heading={`Company  `}
              Contetnt={` (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to coachphilweber.com.`}
            />

            <BoldTextContetentInLine
              Heading={`Device `}
              Contetnt={`means any device that can access the Service such as a computer, a cellphone or a digital tablet.`}
            />

            <BoldTextContetentInLine
              Heading={`Service `}
              Contetnt={`refers to the Website.`}
            />

            <BoldTextContetentInLine
              Heading={`Terms and Conditions `}
              Contetnt={`(also referred as "Terms") mean these Terms and Conditions that form the entire agreement between You and the Company regarding the use of the Service. This Terms and Conditions agreement has been created with the help of the Terms and Conditions Template.`}
            />

            <BoldTextContetentInLine
              Heading={`Third-party Social Media Service`}
              Contetnt={`means any services or content (including data, information, products or services) provided by a third-party that may be displayed, included or made available by the Service.`}
            />

            <BoldTextContetentInLine
              Heading={`Website`}
              Contetnt={` refers to coachphilweber.com, accessible from www.coachphilweber.com`}
            />
            <BoldTextContetentInLine
              Heading={`You `}
              Contetnt={`means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.`}
            />
          </Col>
        </Row>
        <br />
        <div className='heading1'>
          Acknowledgment
        </div>
        <hr />
        <Row>
          <Col md={3}>
          </Col>
          <Col md={9}>
            <br />
            <p className='heading1ContentinnerCont'>
              These are the Terms and Conditions governing the use of this Service and the agreement that operates between You and the Company. These Terms and Conditions set out the rights and obligations of all users regarding the use of the Service.
            </p>
            <p className='heading1ContentinnerCont'>
              Your access to and use of the Service is conditioned on Your acceptance of and compliance with these Terms and Conditions. These Terms and Conditions apply to all visitors, users and others who access or use the Service.
            </p>
          </Col>
        </Row>
       
      </div>
      <Footer />
    </div>
  )
}

export default TermsAndConditions