import React, { useEffect, useState } from 'react';
import "./carousel.css";
import { Row, Col, Card } from "react-bootstrap";

function CorporateSeriesSlider() {
    const [selectedDiv, setSelectedDiv] = useState(false);
    const [isUpdated, setIsUpdated] = useState(true)
    useEffect(() => {
        const galleryContainer = document.querySelector('.gallery-container');
        const galleryControlsContainer = document.querySelector('.gallery-controls');
        const galleryControls = ['previous', 'add', 'next'];
        const galleryItems = document.querySelectorAll('.gallery-item');

        class Carousel {
            constructor(container, items, controls) {
                this.carouselContainer = container;
                this.carouselControls = controls;
                this.carouselArray = [...items];
            }

            // Update css classes for gallery
            updateGallery() {
                this.carouselArray.forEach(el => {
                    el.classList.remove('gallery-item-1');
                    el.classList.remove('gallery-item-2');
                    el.classList.remove('gallery-item-3');
                    // el.classList.remove('gallery-item-4');
                    // el.classList.remove('gallery-item-5');
                    el.classList.remove('selected');
                });

                this.carouselArray.slice(0, 3).forEach((el, i) => {
                    let number =i + 1;                   
                    el.classList.add(`gallery-item-${number}`);
                });
            }

            // Update the current order of the carouselArray and gallery
            setCurrentState(direction) {

                if (direction.className === 'gallery-controls-previous') {
                    this.carouselArray.unshift(this.carouselArray.pop());
                } else {
                    this.carouselArray.push(this.carouselArray.shift());
                }

                this.updateGallery();
            }

            // Construct the carousel navigation
            // setNav() {
            // galleryContainer.appendChild(document.createElement('ul')).className = 'gallery-nav';

            // this.carouselArray.forEach(item => {
            //   const nav = galleryContainer.lastElementChild;
            //   nav.appendChild(document.createElement('li'));
            // }); 
            // }s

            // Construct the carousel controls
            setControls() {
                this.carouselControls.forEach(control => {
                    galleryControlsContainer.appendChild(document.createElement('button')).className = `gallery-controls-${control}`;

                    document.querySelector(`.gallery-controls-${control}`).innerText = control;
                });
            }

            // Add a click event listener to trigger setCurrentState method to rearrange carousel
            useControls() {
                const triggers = [...galleryControlsContainer.childNodes];

                triggers.forEach(control => {
                    control.addEventListener('click', e => {
                        e.preventDefault();
                        //console.log("button click");
                        if (control.className === 'gallery-controls-add') {
                            const newItem = document.createElement('img');
                            const latestItem = this.carouselArray.length;
                            const latestIndex = this.carouselArray.findIndex(item => item.getAttribute('data-index') === this.carouselArray.length) + 1;

                            // Assign the necessary properties for new gallery item
                            Object.assign(newItem, {
                                className: 'gallery-item',
                                src: `http://fakeimg.pl/300/?text=${this.carouselArray.length + 1}`
                            });
                            newItem.setAttribute('data-index', this.carouselArray.length + 1);

                            // Then add it to the carouselArray and update the gallery
                            this.carouselArray.splice(latestIndex, 0, newItem);
                            document.querySelector(`[data-index="${latestItem}"]`).after(newItem);
                            this.updateGallery();

                        } else {
                            this.setCurrentState(control);
                        }

                    });
                });
            }
        }

        const exampleCarousel = new Carousel(galleryContainer, galleryItems, galleryControls);

        exampleCarousel.setControls();
        // exampleCarousel.setNav();
        exampleCarousel.useControls();          

        
    
    }, []);

   

  
     /// addedby me
    //  var classname = document.querySelector(".gallery-item");

    //  var myFunction = function(e) {          
    //      console.log("clicked") 
    //  };
 
    //  for (var i = 0; i < classname.length; i++) {
    //      classname[i].addEventListener('click', myFunction, true);
    //  }

    
    const handleClick = (e) => {

        let element = e.target.closest('.gallery-item-3');
        if(element){
            let getAtttr = element.getAttribute("class");
            let updatedClass ="";
            if(getAtttr.indexOf("selected") === -1){
                updatedClass = getAtttr+ " selected";
            }  
            else{
                updatedClass = getAtttr.split(" selected")[0];
            }          
            element.setAttribute("class", `${updatedClass}`);
        }       
    
    }
   
    return (
        <div className="gallery">
            <div className="gallery-container">
                <div className="gallery-item gallery-item-1" src="http://fakeimg.pl/300/?text=1" data-index="1"   onClick={handleClick}>
                <Card
                        data-index="3">
                        <div className='circle circle3'></div>
                        <Card.Body>
                            <div className='surePeopleheading'></div>
                            <div className='cardtext'>
                                "We are incredibly proud that Coach Phil is a member of our prestigious Leader Labs circuit. As we know, human beings are downright complex and unpredictable. Coach Phil brings real-world experience driven by Ethos, Pathos, and Logos and inspires people to develop effective and lasting habits to increase their Emotional, Relational and Team intelligence ERTi® – resulting in meaningful relationships and extraordinary outcomes.”
                            </div>
                            <div className='secName'>Niko Drakoulis</div>
                            <div className='secDesin'>Chairman & CEO</div>
                        </Card.Body>
                    </Card>
                </div>
                <div className="gallery-item gallery-item-2" src="http://fakeimg.pl/300/?text=2" data-index="2"   onClick={handleClick}>
                    <Card
                        data-index="2">
                        <div className='circle circle2'></div>
                        <Card.Body>
                            <div className='cardtext'>
                                "We're so honored to have Phil be a Coach at Robin. His ability to connect with students is unmatched and kids just keep asking for him to come back!"
                            </div>
                            <div className='secName'>Robin</div>
                            <div className='secDesin'></div>
                        </Card.Body>
                    </Card>
                </div>
                <div className="gallery-item gallery-item-3" src="http://fakeimg.pl/300/?text=3" data-index="3"  onClick={handleClick}>
                <Card
                        data-index="4"
                        
                        >
                        <div className='circle circle1'></div>
                        <Card.Body>
                            <div className='fusanteicheading'></div>
                            <div className='cardtext'>
                                Coach Phil Weber is one of the most passionate, high energy coaches that I have ever been around in my 28 years in professional and elite collegiate sports. Coach Phil helps all individuals that he works with strive to develop a success mindset and he helps them establish a winning game plan so that they can maximize their potential.
                            </div>
                            <div className='secName'>Dr. Mike Clark</div>
                            <div className='secDesin'>CEO Fusionetics</div>
                        </Card.Body>
                    </Card>
                   
                   
                </div>
            </div>
            <div className="gallery-controls"></div>
        </div>
    )
}

export default CorporateSeriesSlider