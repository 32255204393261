import React from 'react';
import { Row, Col } from "react-bootstrap";
import Img1 from "../../assets/t_card_img1.png"
import Img2 from "../../assets/t_img2.svg"
import Img3 from "../../assets/t_img3.svg"

export default function TestimonalCard({ id, name, content, sm }) {
    // console.log(id)
    // console.log(id === 1);
    // console.log(id === 2);
    // console.log(id === 3);
    return (
        <div className='testmonalCardContainer'>
            <div className='testimonalcardcontainer'>
                <div md={id === 1 ? 2 : 4} style={{marginRight:"15px"}}>
                    <img src={id === 1 ? Img1 : id === 2 ? Img2 : Img3} />
                </div>
                <div md={id === 1 ? 10 : 8}>
                    <div className='testMoncardname'>
                        {name}
                    </div>
                </div>
            </div>
            <Row>
                <Col md={12}>
                    <div className='testmonalContnt'>{content}</div>
                </Col>
            </Row>
        </div>

    )
}
