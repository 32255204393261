import React from 'react';
import { Row, Col } from "react-bootstrap";
import Writer1 from "../../assets/writer1_img.png"
import Writer2 from "../../assets/writer2_img.png";
import Writer3 from "../../assets/writer3_img.png";

export default function MybookCard({ id, data, writer }) {
    return (
        <div>
            <div className='bookDetailContainer'>
                <div md={2} style={{ marginRight: "15px" }}>
                    <img src={id == 1 ? Writer1 : (id === 2 ? Writer2 : Writer3)} className="writerimg" />
                </div>
                <div md={10}>
                    <div className='bookContenttxt'>
                        {data}
                    </div>
                    <div className='writerDescription'>
                        {writer}
                    </div>
                </div>
            </div>
            <hr className="divider" />
        </div>

    )
}
