import React from 'react';
import "./speech.css";
import Speechimg from "../../assets/speech.png"

export default function Speech() {
    return (
        <div className='speechContainer'>
            <div className='speechtext'>
                “The truly great have a thirst that can be slaked only by their drive to improve.” -Phil Weber
            </div>
        </div>
    )
}
