import React from 'react';
import { Row, Col, Container } from "react-bootstrap";
import "./about.css";
import Aboutimg from "../../assets/topbar/about.jpg";
import SigntureImg from "../../assets/signature.png"
import QuoteImg from "../../assets/quote.png"

export default function About() {
    return (
        <Container fluid className='aboutContainer' id="about">
            <Row>
                <Col md={1}></Col>
                <Col md={10}>
                    <Row>
                        <Col md={6}>
                            <img src={Aboutimg} className="aboutimage" alt='Loading iamge ....' />
                        </Col>
                        <Col md={6}>
                            <p className='contentHeader'>About</p>
                            <div className='content'>
                                Trailblazer Phil Weber has been guiding men toward victory in the world of basketball and beyond. Globally on and off the court, from New York, to Phoenix, to India, Phil's life-changing strategies have elevated some of the biggest names in the game. Through his new page turner, “How Do You Make Them Thirsty?”, the professional basketball coach, public speaker, and now author fires up readers with wit, wisdom, and cutting edge insights for life in his book.
                                <div className='quoteimgparent'>
                                    <img src={QuoteImg} className="quoteimg" alt='Loading iamge ....' />
                                </div>
                            </div>
                            <p>
                                <img src={SigntureImg} className="signature" alt='Loading iamge ....' />
                            </p>
                        </Col>
                    </Row>
                </Col>
                <Col md={1}></Col>
            </Row>
        </Container>
    )
}
