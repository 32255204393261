import React from 'react';
import "./latestNews.css";
import Newsbackground from "../../assets/latestnews.png"
import { Container, Row, Col } from 'react-bootstrap';

export default function LatestNews() {
    return (
        <div className='newsContainer'>
            <div className='text'>
                Latest news and Podcasts
            </div>
        </div>
    )
}
