import React from 'react';
import { Row, Col, Container } from "react-bootstrap";
import "./mybook.css";
import MybookCard from './MybookCard';
import buyBtnIcon from "../../assets/buybtnicon.svg";

export default function MyBook() {
  return (
    <Container className='bookContainer' id="books">
      <Row>
        <Col md={6}>
          <div className='bookImageContainer'></div>
        </Col>
        <Col md={6}>
          <div className='bookcontentHeader'>My book</div>
          <MybookCard
            id={1}
            data={`“Phil is a relentlessly positive leader. You will enjoy this fun ride and learn important strategies on how to get the most out of individuals and teams. This book is for empowering thoughts only! “

                `}
            writer={`Erik Spoelstra, NBA Coach, Miami Heat`}
          />
          <MybookCard
            id={2}
            data={`“Phil has a great natural energy, passion, and curiosity in his blood.  He has studied the process of player development as much as anyone  I know. His book weaves all of that together with great examples  and real stories that make this a fun, instructional, and fulfilling read.”`}
           
            writer={`Danny Ferry, former NBA player and NBA front office executive`}
          />
          <MybookCard
            id={3}
            data={`“Leadership aficionados in sports and in life will find many nuggets to grab ahold of in these pages, as Coach Phil Weber offers lessons and stories from working with some of the most elite talent and teams on the planet.” 


            `}
            writer={`Bob Beaudine, president and CEO, Eastman & Beaudine;
            USA Today best-selling author of The Power of Who and 2 Chairs`}
          />

          <div className='mybookgrayHeader'>My book</div>
          <div className='makethirstytxt'>How do you make them thirsty?</div>
          {/* <div className='preorderTxt'>Preorder Now</div> */}
          <div className='buyBtnContainer'>
            <a className='BuyBtn' target="_blank" href='https://www.amazon.com/How-You-Make-Them-Thirsty/dp/1642251984/ref=sr_1_1?crid=1Y75U8910Y8IK&keywords=phil+weber&qid=1650381833&s=books&sprefix=phil+weber%2Cstripbooks%2C136&sr=1-1' >
              <span>
                <img src={buyBtnIcon} className='buyBtnicn' alt='Loading..' />
              </span>
              <span className='BuyBtntxt'>
                Buy
              </span>
            </a>
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={12}>

        </Col>
      </Row>
    </Container>
  )
}
