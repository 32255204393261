import React, { useState } from 'react';
import { Navbar, Container, Nav } from "react-bootstrap";
// import "./topbar.css";
import { Link } from 'react-scroll'
import facebook from "../../assets/fb_black.svg";
import instagram from "../../assets/insta_black.svg";
import twitter from "../../assets/twitter_black.svg";
import linkdin from "../../assets/linkdin_black.svg";

function Header() {
    const [showIcon, setShowIcon] = useState(false);
    const contronnavFn = (e) => {
        console.log(e.target.getAttribute("class"));
       let classData = (document.getElementsByClassName("collapse")[0].getAttribute("class"));
      if(classData.indexOf("show") > -1){
        setShowIcon(false);
      }
      else{
        setShowIcon(true);
      }
    }

    const closeMenu = (e) => {
        setShowIcon(false);
    };

    return (
        <Navbar collapseOnSelect expand="lg">
            <Container fluid>
                <Navbar.Brand href="/">
                    <span className='brandName'>Phil Weber</span>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav sam" onClick={contronnavFn} className = {showIcon ? "hideIcon" : ""} />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">

                    </Nav>
                    <Nav>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" className='customClose' onClick={closeMenu} />
                        <Link to="home" spy={true} smooth={true} offset={15} duration={500} className="nav-link">Home</Link>
                        <Link to="about" spy={true} smooth={true} offset={15} duration={500} className="nav-link">About</Link>
                        <Link to="corporateSeries" spy={true} smooth={true} offset={15} duration={500} className="nav-link">Corporate Series</Link>
                        <Link to="latestNews" spy={true} smooth={true} offset={15} duration={500} className="nav-link">Latest news</Link>
                        <Link to="books" spy={true} smooth={true} offset={15} duration={500} className="nav-link">Books</Link>
                        <Link to="contact" spy={true} smooth={true} offset={15} duration={500} className="nav-link">Contact</Link>
                        <div className='navFooteritem'>
                            <div className='navFooteritemHeader'>GET IN TOUCH</div>
                            <div className='navFooterSocialIcon'>
                                <div style={{ display: "flex", justifyContent: "center", margin: "auto" }}>
                                    <a href='#' target="_blank">
                                        <img src={facebook} className="socialSiteIconTopbar fbook" />
                                    </a>
                                    <a href='https://instagram.com/coachphilweber?utm_medium=copy_link' target="_blank">
                                        <img src={instagram} className="socialSiteIconTopbar" />
                                    </a>
                                    <a href='https://twitter.com/CoachPhilWeber?t=E93awCRPR7txuQi6zeWpQw&s=08' target="_blank">
                                        <img src={twitter} className="socialSiteIconTopbar" />
                                    </a>
                                    <a href='https://www.linkedin.com/in/phil-weber-03243a3/' target="_blank">
                                        <img src={linkdin} className="socialSiteIconTopbar" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </Nav>

                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default Header