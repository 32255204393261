import React from 'react';
import { Card, Row, Col } from "react-bootstrap";
import img1 from "../../assets/news1.png";
import img2 from "../../assets/news2.png";
import img3 from "../../assets/news3.png";
import img4 from "../../assets/news4.png";

function NewsCard({ id, link, linkText }) {
    return (
        <a href={link} target="_blank">
            <Card className='newsCard'>
                <Card.Img variant="top" src={id == 1 ? img1 : (id === 2) ? img2 : (id === 3 ? img3 : img4)} />
                <Card.Body className='p-0'>               
                    <div className='linkouterContainer'>
                        <div className='linkContainer' href={link} target="_blank">{linkText}</div>
                    </div>
                </Card.Body>
            </Card>
        </a>
    )
}

export default NewsCard