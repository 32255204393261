import React from 'react'

function HeadingWithboldTextCpontent({Content, Heading}) {
    return (
        <div>
            <div className='heading1ContentSubheading1'>
               {Heading}
            </div>
            <div className='heading1ContentinnerCont'>
                {Content}
            </div>
        </div>
    )
}

export default HeadingWithboldTextCpontent
