import { BrowserRouter, Routes, Route, } from "react-router-dom";
import Home from './pages/Home';
import PrivacyPolicies from './pages/PrivacyPolicies';
import TermsAndConditions from "./pages/TermsAndConditions";

function App() {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route exact path='/privacypolicy' element={<PrivacyPolicies />} />
          <Route exact path='/termsandconditions' element={<TermsAndConditions />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
