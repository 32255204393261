import React from 'react';
import { Card, Col, Row } from "react-bootstrap";
import Img1 from "../../assets/potcasts01.png";
import Img2 from "../../assets/potcasts02.jpg"
import Img3 from "../../assets/potcasts03.jpg"
import Img4 from "../../assets/potcasts04.jpg"

export default function PodCastCard({ id, name, subText, link }) {
    return (
        <a  href={link} style={{ textDecoration: "none" }}  target="_blank">
            <Card className='podcastcard'>
                <Card.Body className={id === 1 ? `p-0 podCastCardBody firstCard` : id === 2 ? 
                `p-0 podCastCardBody seccondCard`
                           :
                            id === 3 ? `p-0 podCastCardBody thirdCard` :
                            `p-0 podCastCardBody fourthCard`}                  
                >
                    <div>


                        <Row>
                            <Col md={2} sm={12} className="">
                                <div className='podCastImageContainer'>
                                    <img src={id === 1 ? Img1 : id === 2 ? Img2 : id === 3 ? Img3 : Img4} />
                                </div>
                            </Col>
                            <Col md={10} sm={12} className="">
                                <div className='podCastCardContent'>
                                    <div className='podcastname'>{name}</div>
                                    <div className='podcastFooter'>
                                        {subText}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <div>
                            <div className='podCastCardBottomDiv'
                            //  target="_blank"
                            //  href={link} 
                             style={id === 1 ? { background: "#3F81CD" }
                                : id === 2 ? { background: "#828282" } :
                                    id === 3 ? { background: "#0F1C92" } : { background: "#3F81CD" }
                            }></div>
                        </div>                        
                    </div>
                </Card.Body>
            </Card>
        </a>

    )
}
