import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import "./podcast.css";
import PodCastCard from "./PodCastCard";

export default function PodcacastControl() {
    return (
        <div className='podcastContainer'>
            <Container>
                <div className='podcastHeader'>Podcasts</div>
                <Row>
                    <Col md={1}></Col>
                    <Col md={10} className="podcastCardContainer">
                        <PodCastCard
                            id={1}
                            name="Episode 06 - Part One: How Do You Make Them Thirsty"
                            subText="The BOSS Podcast with Gregory Shepard"
                            link="https://podcasts.apple.com/us/podcast/episode-06-part-one-how-do-you-make-them-thirsty/id1509803992?i=1000490812605"
                        />
                        <PodCastCard
                            id={2}
                            name="Phil Weber on Growing from Moments"
                            subText="BRIAN LEVENSON"
                            link="https://www.strongskills.co/podcast-feed/weber"
                        />
                        <PodCastCard
                            id={3}
                            name="Phil Weber: Good Questions Transform Lives"
                            subText="The Silver Lining Phoenix"
                            link="https://podcasts.apple.com/us/podcast/the-silver-lining-phoenix/id1519634323?i=1000518711429"
                        />
                        <PodCastCard
                            id={4}
                            name="S1, E6 • NBA Coaching Legend Phil Weber"
                            subText="Around The Association"
                            link="https://open.spotify.com/episode/4nwgu6H2Mq3FobcrLos1w8?si=B-IQEr1kShWUMFcmM8Qbvg&nd=1"
                        />
                    </Col>
                    <Col md={1}></Col>
                </Row>
            </Container>
        </div>
    )
}
