import React from 'react';

function BoldTextContetentInLine({Heading, Contetnt}) {
    return (
        <div className='heading1ContentinnerCont'>
            <span className='boldtext'>{Heading} </span> {Contetnt}
        </div>
    )
}

export default BoldTextContetentInLine