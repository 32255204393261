import React from 'react';
import { Row, Col } from "react-bootstrap";
import "./news.css";
import NewsCard from './NewsCard';

export default function News() {
    return (
        <div className='container' id="latestNews">
            <div className='text-center latestNewstext'>Latest news</div>
            <Row>
                <Col md={3}>
                    <NewsCard
                        id={1}
                        link="https://bleacherreport.com/articles/2540083-the-anthony-davis-architect-can-phil-weber-maximize-the-brow"
                        linkText="Unleash the Brow"
                    />
                </Col>
                <Col md={3}>
                    <NewsCard
                        id={2}
                        link="https://amp.scroll.in/article/843933/from-nba-to-india-new-basketball-coach-phil-weber-hopes-to-create-a-culture-of-success"
                        linkText="From NBA to India"
                    />
                </Col>
                <Col md={3}>
                    <NewsCard
                        id={3}
                        link="https://nypost.com/2020/01/27/ex-knicks-assistant-relives-night-kobe-bryant-took-over-msg/"
                        linkText="Kobe Bryant"
                    />
                </Col>
                <Col md={3}>
                    <NewsCard
                        id={4}
                        link="https://www.sun-sentinel.com/sports/fl-xpm-2014-09-23-sfl-miami-heat-phil-weber-s092314-story.html"
                        linkText="Heat D-League coach"
                    />
                </Col>
            </Row>

        </div>
    )
}
